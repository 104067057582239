import * as React from "react"
import Layout from "../components/layout"

export default function About() {
  return <Layout>
  	<div class="content">
      <p>Josephine Weidner is a software engineer based in Brooklyn, NY. After graduating from Tufts University with a BS in Computer Science in 2020, Josephine joined Corvus Insurance, where she enjoys programming in Elixir and Elm and the collaborative work environment. </p>
      <p>Josephine’s favorite part of being a software engineer is arming non-engineering folks with the technical knowledge they need to make a good decision. Josephine loves to weigh in on product decisions, and always has the end-user in mind. That said, Josephine cannot go more than a handful of days without coding, and her brain craves the feeling of seeing something compile, something work, something become "real" for the first time.</p>
      <p>Josephine’s ideal day starts with coffee and a novel in Prospect Park and ends with a home-cooked meal with her friends. Josephine also enjoys learning languages (and is currently taking a Spanish class through Instituto Cervantes - check them out!).</p>
    </div>
  </Layout>
}